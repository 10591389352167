<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">{{isNew?'اضافة مدينة':'تعديل مدينة'}}</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                          <Select id="country_id" title="الدولة" :Items="CountriesCustom" Mykey="country_id" :errors="service_error" :MyValue="form.country_id" @Select="HandleSelect('country_id',$event)"/>

                        <Input id="name" title="الاسم بالعربي" @Input="HandleNameInput('ar',$event)" Mykey="name.ar" :errors="service_error" :MyValue="form.name.ar"/>
                        <Input id="area" title="الاسم بالانجليزي" @Input="HandleNameInput('en',$event)" Mykey="name.en" :errors="service_error" :MyValue="form.name.en"/>

                    </div>

                    <button type="button" class="btn btn-primary pull-right" v-if="isNew"  @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right" v-else  @click="onUpdate()">تعديل </button>
                     <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../../components/Form/TextInput'
  import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
import SuccessAlert from '../../../../components/SuccessAlert'
import Select from '../../../../components/Form/TextSelect'

export default {
  computed:{
    ...mapState({
        City:state=>state.admin.cities.City.data,
        service_error:state=>state.admin.cities.City.errors,
        CountriesList:state=>state.admin.countries.CountriesList,

    }),

    isNew(){
      return this.form.id == '';
    },

    CountriesCustom(){
        let Countries=[];
        this.CountriesList.data.forEach(element => {
            Countries.push({
                id:element.id,
                name:element.name.ar
            })
        });
        return Countries;
    }
 
   },
   data(){
       return{
           form:{
               id:'',
               name:{
                   ar:'',
                   en:''
               },
               country_id:'',
             },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanCityErrors"
       }),
     setData(vm){
            vm.form.id=vm.City.id;
            vm.form.name.ar=vm.City.name.ar;
            vm.form.name.en=vm.City.name.en;
            vm.form.country_id=vm.City.country.id;
        },
       
       onSubmit(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/storeCity',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateCity',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleNameInput(key,value){
         this.form['name'][key]=value;
     },
     HandleSelect(key,value){
         this.form[key]=value;
     },
   },
   components:{
       Input,
       SuccessAlert,
       Select
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const id = to.params.id; 
        store.dispatch('admin/fetchCountriesList',{page:1,search:'',paginate:1,limit:999}).then(()=>{

        if (typeof id === "undefined") {
            next(vm => {
       //       store.commit('admin/PleaseStopLoading');
              //  vm.isLoading = false;
            });
         } else {
             store.dispatch('admin/fetchCity', id).then((response) => {
                next(vm => {
                    vm.setData(vm);
         //           store.commit('admin/PleaseStopLoading');
               //     vm.isLoading = false;
                });


            });
        }
         });
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>